#player {
    background: rgba(0,0,0,1);
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
}

.close-player {
    font-size: 4rem;
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none;
    color: #fff;
    padding: var(--space-m);
    line-height: .5em;
    display: block;
    cursor: pointer;
}

.close-player:hover, .close-player:focus {
    filter: drop-shadow(0 0 10px rgba(255,255,255,1))
}