:root {
    /*--tile-width: 320px;*/
    /*--tile-height: 180px;*/
    --tile-width: 280px;
    --tile-height: 157.5px;
}

.work-grid {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: wrap;*/
    display: grid;
    gap: 10px;
    grid-auto-rows: auto; /* minmax(100px, auto);*/
    /*grid-template-columns: repeat(6, minmax(1fr));*/
    grid-template-columns: repeat(auto-fill, minmax(var(--tile-width), 1fr));
    grid-auto-flow: dense;
    user-select: none;
}