.video-tile {
    position: relative;
    aspect-ratio: 320/180;

    grid-column: auto;
    grid-row: auto;
    outline: 4px transparent solid;
    transition: all .2s ease-out;
    overflow: hidden;
}

.video-tile:hover {
    outline: 4px #fff solid;
    transform: scale(1.05);
    z-index: 3;
}
.video-tile:active {
    filter: drop-shadow(0 0 40px rgba(255,255,255,.5))
}

.video-tile h4 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: var(--space-m);
    padding-top: 2em;
    text-align: center;
    font-size: 1.25rem;
    background: linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0.6) 50%,transparent);
    line-height: 1em;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 400;
    z-index: 2;
}

.video-tile img {
    width: 100%;
    position: absolute;
    z-index: 1;
}