:root {
  --space-s: 10px;
  --space-m: 20px;
  --space-l: 40px;
}

body {
  background: #000;
  color: #FFF;
  font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
}

#header {
  padding: var(--space-m);
  display: flex;
  flex-direction: row;
}

#logo {
  align-self: center;
  margin-right: auto;
}

#logo > img {
  max-width: 25vw;
  min-width: 200px;
}

#nav {
  align-self: center;
}

#nav a, #nav a:link {
  font-size: 2rem;
  color: #FFF;
  text-decoration: none;
  margin-left: 1vw;
  text-transform: uppercase;
  font-weight: 400;
}

#nav a:first-child {
  margin-left: 0;
}

#nav a:link:hover {
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, .5);
}

#nav a:link.active {
  text-decoration: underline;
}

#content {
  padding: var(--space-l) var(--space-m);
}

h1.page-title {
  text-align: center;
  font-size: 3rem;
  padding: 0;
  margin: 0;
  margin-bottom: var(--space-m);
  line-height: 1em;
}

@media (max-width: 450px) {
  #content {
    padding: 0;
  }

  #header {
    flex-direction: column;
    gap: 5px;
  }
  #nav a, #nav a:link {
    font-size: 1rem;
  }

  #logo {
    margin-right: 0;
  }
}