.about-profile {
    display: flex;
    column-gap: var(--space-m);
}

.about-image {
    min-width: 40%;
}

.about-image > img {
    width: 100%;
    height: auto;
}

.about-details {

}

.about-details h2 {
    font-size: 2rem;
    margin: 0;
    line-height: 1em;
}

.about-details p {
    line-height: 1em;
    margin: var(--space-s) 0;
    text-align: justify;
}

.about-title {
    font-size: 1.5rem;
}