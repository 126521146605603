.contact {
    font-size: 3rem;
    text-align: center;

    display: grid;
    gap: 0;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    grid-auto-flow: dense;

    font-weight: 100;
}

.contact-image {
    position: relative;
    /*transform: scale(1.05);*/
    /*transform-origin: bottom right;*/
    z-index: 1;
    height: 400px;
}


.contact-image > div {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.contact-image:first-child > div {
    bottom: -20px;
    right: -20px;
}

.contact-image > div:after {
    content: ' ';
    display: block;
    background: linear-gradient(0deg, rgba(0, 0, 0, .7) 15%, rgba(0, 0, 0, 0) 50%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.contact-image h2,
.contact-image h3 {
    transition: 0.4s ease-out all;
    color: #fff;
    font-size: 2rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 3em;
    margin: 0;
    padding: 0;
    z-index: 2;
    font-weight: 100
}

.contact-image h2 {

}

.contact-image h3 {
    opacity: 0;

}

.contact-image:hover h2 {
    opacity: 0
}

.contact-image:hover h3 {
    opacity: 1
}

.contact-image:hover {
    z-index: 2;
}

.contact-image img {
    max-height: 400px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.contact > div {
    align-self: center;
}

.contact p {
    margin: var(--space-m);
}

.motion-1 {
    animation: motion-1;
    animation-iteration-count: infinite;
    animation-duration: 60s;
    animation-timing-function: linear;
}

.motion-2 {
    animation: motion-2;
    animation-iteration-count: infinite;
    animation-duration: 60s;
    animation-timing-function: linear;
}

.motion-3 {
    animation: motion-3;
    animation-iteration-count: infinite;
    animation-duration: 30s;
    animation-timing-function: linear;
}

.motion-4 {
    animation: motion-4;
    animation-iteration-count: infinite;
    animation-duration: 30s;
    animation-timing-function: linear;
}

@keyframes motion-1 {
    0% {
        transform: translateX(-50px);
    }
    50% {
        transform: translateX(50px);
    }
    100% {
        transform: translateX(-50px);
    }
}

@keyframes motion-2 {
    0% {
        transform: translateX(50px);
    }
    50% {
        transform: translateX(-50px);
    }
    100% {
        transform: translateX(50px);
    }
}

@keyframes motion-3 {
    0% {
        transform: translateX(-50px);
    }
    50% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-50px);
    }
}

@keyframes motion-4 {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-50px);
    }
    100% {
        transform: translateX(0px);
    }
}